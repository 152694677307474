import { FC, useCallback, useMemo } from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

import { IconArrowDown } from '@assets/icons';
import { useLocale } from '@core/utils/locale';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { useSelectInput } from '@core/hooks/useInput';
import { replaceRouteParam } from '@core/utils/string';
import Select from '@components/utility/Inputs/Select/Select';
import TableTag from '@components/utility/Chips/Tag/TableTag';
import { GradientColorsEnum } from '@core/enums/gradientEnum';
import Text, { TextVariant } from '@components/ui/Typography/Text';
import Widget, { WidgetType } from '@components/utility/Widget/Widget';
import { SelectOption } from '@components/utility/Inputs/Inputs.helpers';
import { useTableContext } from '@components/utility/Table/TableContext';
import { useAdvancedSearch } from '@core/contexts/AdvancedSearch.context';
import { FeatureFlags, useFeatureFlag } from '@core/hooks/useFeatureFlag';
import { buildOperatorAddress } from '@components/utility/Table/Table.helpers';
import TableFeedback from '@components/utility/Table/TableFeedback/TableFeedback';

import './OperatorResultsTableMobile.styles.scss';
import { OperatorTargetingData } from '../../hooks/useOperatorResultsTable';

const OperatorResultsTableMobile: FC<{
    data: OperatorTargetingData;
    testId: string;
    loading: boolean;
}> = ({
    data,
    testId,
    loading
}) => {
    const { t } = useLocale();
    const navigate = useNavigate();
    const { config } = useTableContext();
    const isCustomerStatusTableEnabled = useFeatureFlag(FeatureFlags.CustomerStatusTable);

    const {
        value,
        onChange
    } = useSelectInput();

    const {
        advancedSearchSort: {
            onSort,
            sortedColumns
        },
    } = useAdvancedSearch();

    const selectOptions: SelectOption[] = useMemo(
        () => config.filter((c) => c.isSortable).map((c) => ({ label: c?.title ? t(c?.title) : '', id: c.field })),
        [config, t],
    );

    const getTotalUnitsText = useCallback((totalUnits?: number | null) => {
        if (totalUnits) {
            return `${totalUnits} total unit${totalUnits > 1 ? 's' : ''}`;
        }

        return '1 total unit';
    }, []);

    return (
        <Widget type={WidgetType.Table} testId={testId}>
            <TableFeedback isLoading={loading} isEmpty={(data?.operators?.length ?? 0) === 0} />
            <div className="OperatorResultsTableMobile__header">
                <Text variant={TextVariant.B2} testId="sortLabel">
                    SORT:
                </Text>
                <div style={{ minWidth: '120px' }}>
                    <Select
                        value={value ?? sortedColumns[0].field}
                        height="1px"
                        onChange={(value) => {
                            if (value) {
                                onSort(value);
                            }
                            onChange(value);
                        }}
                        options={selectOptions}
                        testId="selectSortedColumn"
                    />
                </div>
                <div
                    onClick={() => onSort(sortedColumns[0].field)}
                    className={cn('OperatorResultsTableMobile__sort', {
                        'OperatorResultsTableMobile__sort--desc': sortedColumns[0].order === 'DESC',
                    })}
                    data-testid="tableMobileSortIcon"
                >
                    <IconArrowDown />
                </div>
            </div>
            <div className="OperatorResultsTableMobile__body">
                {data?.operators?.map((row, index) => (
                    <>
                        <div className="OperatorResultsTableMobile__divider" />
                        <a
                            key={index}
                            className="OperatorResultsTableMobile"
                            data-testid={`tableRow${index}`}
                            onClick={() => {
                                navigate(
                                    replaceRouteParam(
                                        RoutesEnum.OperatorProfile,
                                        ':id',
                                        row?.operator_identifier ?? '',
                                    ),
                                );
                            }}
                        >
                            <div className="OperatorResultsTableMobile__name" data-testid="tableCellName">
                                <strong>{row?.business_name}</strong>
                            </div>
                            {Object.keys(row?.location ?? {}).length > 1 &&
                                buildOperatorAddress(row?.location, row?.phone)}

                            {(row?.segment || row?.sub_cuisine) && (
                                <div className="OperatorResultsTableMobile__row">
                                    {row?.segment && <div data-testid="tableCellSegment">{row?.segment}</div>}
                                    {row?.cuisine && <div data-testid="tableCellCuisine">{row?.sub_cuisine}</div>}
                                </div>
                            )}
                            <div className="OperatorResultsTableMobile__row">
                                <div data-testid="tableCellTotalUnits">
                                    {getTotalUnitsText(row?.parent_chain?.number_of_units)}
                                </div>
                            </div>
                            {isCustomerStatusTableEnabled && row?.company_data?.status && (
                                <div className="OperatorResultsTableMobile__row">
                                    <div data-testid="tableCellCustomerStatus">
                                        <TableTag
                                            name={row?.company_data?.status}
                                            testId="CustomerStatus"
                                            baseColor={GradientColorsEnum.PURPLE}
                                        />
                                    </div>
                                </div>
                            )}
                        </a>
                    </>
                ))}
            </div>
        </Widget>
    );
};

export default OperatorResultsTableMobile;
