import { ID } from '@core/models';
import { CriterionParam } from '@core/models/Criterion';
import { DropdownOption } from '@components/utility/Inputs/Dropdowns/Dropdowns.helpers';

import Retail from '../components/InactiveContent/Retail';
import { ClassificationDTO } from './Classification.helpers';
import TagsContent from '../components/TagsContent/TagsContent';
import NonCommercial from '../components/InactiveContent/NonCommercial';
import LocationContent from '../components/LocationContent/LocationContent';
import SegmentsContent from '../components/SegmentsContent/SegmentsContent';
import ChooseYourCriteria from '../components/ChooseYourCriteria/ChooseYourCriteria';
import LodgingAndRecreation from '../components/InactiveContent/LodgingAndRecreation';
import GeneralCriteria from '../components/SegmentsCriteriaContent/GeneralCriteria/GeneralCriteria';
import SegmentsCriteriaContent from '../components/SegmentsCriteriaContent/SegmentsCriteriaContent';
import RestaurantsAndBarsCriteria from '../components/SegmentsCriteriaContent/RestaurantsAndBarsCriteria/RestaurantsAndBarsCriteria';

export {
    LocationContent,
    SegmentsContent,
    RestaurantsAndBarsCriteria,
    LodgingAndRecreation,
    Retail,
    NonCommercial,
    TagsContent,
    GeneralCriteria,
    ChooseYourCriteria,
    SegmentsCriteriaContent,
};

export type SwitchGroupProps = {
    state: ID[][];
    setStateItem: (index: number) => (value: ID[]) => void;
};

export type SwitchListProps = {
    listState: ID[][];
    setStateListItem: (index: number) => (value: ID[]) => void;
};

export type TagItem = {
    id: ID;
    title: string;
};

type CriteriaFilterOption = {
    id: ID;
    title: string;
    subtitle?: string;
};

export type CriteriaFilter = {
    title: string;
    options: CriteriaFilterOption[];
    isSmall?: boolean;
};

export type DefaultCriteriaSectionProps = {
    isSmallScreen: boolean;
};

export function getColumnsClass(isMobile: boolean, defaultAmount = 4, mobileAmount = 1): string {
    if (isMobile) {
        return `col-${mobileAmount}`;
    }
    return `col-${defaultAmount}`;
}

export function mapCriterionParamsToDropdownOptions(params: CriterionParam[]): DropdownOption[] {
    return params.map((param) => ({
        id: param.value,
        value: param.value.toString(),
        label: param.name,
    }));
}

export function handlePillSearchHighlighting(
    subChannel: ClassificationDTO,
    searchSelectedId: ID | undefined,
    onClearSearch: () => void,
) {
    const abortController = new AbortController();

    const highlightedPill =
        subChannel?.children?.find((cuisine) => cuisine?.external_id === searchSelectedId) ??
        (searchSelectedId === subChannel?.external_id ? subChannel : null);

    if (highlightedPill) {
        const highlightedElement = document.getElementById(highlightedPill.external_id as string);
        highlightedElement?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
        });

        highlightedElement?.addEventListener('click', onClearSearch, { once: true, signal: abortController.signal });
    }

    return { abortHighlightedPillListener: () => abortController.abort() };
}

export function highlightSegmentIfSelected(selectedId?: ID, segmentId?: ID, title?: string) {
    return segmentId === selectedId ? <span style={{ background: '#d2f6e8' }}>{title}</span> : (title ?? '');
}

export const getSafeBottomMargin = () => {
    const contentRoot = document.querySelector('#observer-root');
    const rootHeight = contentRoot?.getBoundingClientRect()?.height ?? 0;

    return rootHeight * 0.8;
};
