import { useState, useEffect, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { ID } from '@core/models';
import { useLocale } from '@core/utils/locale';
import { Country } from '@core/enums/flagsEnum';
import { useAdvancedSearch } from '@core/contexts';
import { RoutesEnum } from '@core/enums/RoutesEnum';
import { toggleInArray } from '@core/helpers/helpers';
import { CRITERIA } from '@core/hooks/useAdvancedSearchCriteria';
import useTerritories, { TerritoryDTO } from '@core/api/useTerritories';
import { useSelectCountryModal } from '@components/features/SelectCountryModal';
import { Dictionary, LocalesEnum, TerritoriesPhrasesEnum } from '@core/enums/localeEnum';
import { CriteriaData, CriterionEnum, CriterionParam, CriterionParamFilter } from '@core/models/Criterion';

export type TerritorySelection = {
    toggleById: (id: ID) => void;
    isSelected: (id: ID) => boolean;
    isAllSelected: boolean;
    toggleAll: () => void;
    selectedTerritoryIds: ID[];
};

type UseTerritoriesPageReturn = {
    territoriesList: TerritoryDTO[];
    isTerritoriesLoading: boolean;
    territorySelection: TerritorySelection;
    countryModal: ReturnType<typeof useSelectCountryModal>;
    targetTerritoriesAndNavigate: (route: RoutesToNavigate) => void;
};

type RoutesToNavigate = RoutesEnum.OperatorTargetingCriteria | RoutesEnum.OperatorTargetingResults;

function useTerritoriesPage(): UseTerritoriesPageReturn {
    const [selectedTerritoryIds, setSelectedTerritoryIds] = useState<ID[]>([]);

    const { locale } = useLocale();
    const navigate = useNavigate();
    const countryModal = useSelectCountryModal();

    const {
        activeCountry,
        onMergeCriteria,
        onAddCriterionParam,
        getCriterionByFilterKey,
        onResetCriteriaByFilterKey
    } =
        useAdvancedSearch();

    const {
        data,
        loading: isTerritoriesLoading,
        doFetch
    } = useTerritories();

    const territoriesList = useMemo(() => getTerritoriesByTier(data?.territories ?? [], 1), [data?.territories]);

    const territoriesCriteria = useMemo(
        () => getCriterionByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories) ?? [],
        [getCriterionByFilterKey],
    );

    useEffect(() => {
        if (activeCountry?.value) {
            countryModal.setSelectedCountry({ name: activeCountry.value, code: activeCountry.value } as Country);
            doFetch({ params: { country: activeCountry.value as string } });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [doFetch, activeCountry?.value]);

    useEffect(() => {
        const territoryCriteriaIds =
            territoriesCriteria
                ?.filter((trCriterion) => territoriesList.some((tr) => tr.name === trCriterion.value))
                .map((trCriterion) => trCriterion.value) || [];

        setSelectedTerritoryIds(territoryCriteriaIds);
    }, [territoriesCriteria, territoriesList]);

    const onAddTerritoriesToCriteria = useCallback(
        (trList: TerritoryDTO[]) => {
            const territoryParams = mapTerritoriesToCriterionParam(trList, locale);
            territoryParams?.forEach((territoryParam) => {
                onAddCriterionParam(CriterionEnum.Location, territoryParam);
            });
        },
        [locale, onAddCriterionParam],
    );

    const onToggleTerritory = useCallback(
        (trId: ID) => {
            const newSelectedTerritories = toggleInArray(selectedTerritoryIds, trId);
            const activeTerritoryCriteria = territoriesList.filter((tr) => newSelectedTerritories.includes(tr.name));

            setSelectedTerritoryIds(newSelectedTerritories);
            onResetCriteriaByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories);
            onAddTerritoriesToCriteria(activeTerritoryCriteria);
        },
        [onAddTerritoriesToCriteria, onResetCriteriaByFilterKey, selectedTerritoryIds, territoriesList],
    );

    const onToggleAllTerritories = useCallback(() => {
        if (selectedTerritoryIds.length === territoriesList.length) {
            onResetCriteriaByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories);
            return;
        }

        setSelectedTerritoryIds(territoriesList.map(({ name }) => name));
        onResetCriteriaByFilterKey(CriterionEnum.Location, CriterionParamFilter.Territories);
        onAddTerritoriesToCriteria(territoriesList);
    }, [onAddTerritoriesToCriteria, onResetCriteriaByFilterKey, selectedTerritoryIds.length, territoriesList]);

    const targetTerritoriesAndNavigate = useCallback(
        (route: RoutesToNavigate) => {
            if (!activeCountry) return;

            const territoryCriteria = getTerritoryCriteria([activeCountry, ...territoriesCriteria]);

            onMergeCriteria(territoryCriteria);
            navigate(route, { state: { from: RoutesEnum.Territories } });
        },
        [activeCountry, navigate, onMergeCriteria, territoriesCriteria],
    );

    const handleCountryChange = useCallback(
        (country: Country) => {
            const newCountryCriterion: CriterionParam = {
                value: country.code,
                name: country.name,
                filterKey: CriterionParamFilter.Country,
                isPermanent: true,
            };

            onAddCriterionParam(CriterionEnum.Location, newCountryCriterion);
            countryModal.setIsModalOpen(false);
        },
        [onAddCriterionParam, countryModal],
    );

    return {
        territoriesList,
        isTerritoriesLoading,
        targetTerritoriesAndNavigate,
        territorySelection: {
            toggleById: onToggleTerritory,
            toggleAll: onToggleAllTerritories,
            isSelected: (id: ID) => selectedTerritoryIds.includes(id),
            isAllSelected: selectedTerritoryIds.length === territoriesList.length,
            selectedTerritoryIds,
        },
        countryModal: {
            ...countryModal,
            handleCountryChange,
        },
    };
}

function getTerritoriesByTier(territories: TerritoryDTO[], tier: 1 | 2 | 3): TerritoryDTO[] {
    const result: TerritoryDTO[] = [];

    function traverse(territory: TerritoryDTO) {
        if (territory.tier === tier) {
            result.push(territory);
        }
        if (territory.children) {
            territory.children.forEach((child) => traverse(child));
        }
    }

    territories.forEach((territory) => traverse(territory));

    return result;
}

export function mapTerritoriesToCriterionParam(dtos: TerritoryDTO[], locale: LocalesEnum): CriterionParam[] {
    return dtos.map((dto) => ({
        value: dto.name,
        name: `${Dictionary[TerritoriesPhrasesEnum.Territories][locale]}: ${dto?.name}`,
        filterKey: CriterionParamFilter.Territories,
        additionalData: { details: dto?.details, condensedLabel: dto?.name },
    }));
}

export function getTerritoryCriteria(criterionParams: CriterionParam[]): Partial<CriteriaData> {
    return {
        [CriterionEnum.Location]: {
            ...CRITERIA[CriterionEnum.Location],
            CriterionParams: criterionParams,
        },
    };
}

export default useTerritoriesPage;
